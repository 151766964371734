export type Experience = {
    title: string,
    id: number,
};

export const ExperienceList: Experience[] = [
    {
        title: 'Без опыта',
        id: 0,
    },
    {
        title: 'До 6 мес.',
        id: 1,
    },
    {
        title: 'От 6 мес. до 1 года',
        id: 2,
    },
    {
        title: 'От 1 до 3 лет',
        id: 3,
    },
    {
        title: 'Более 5 лет',
        id: 4,
    },
];