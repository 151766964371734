<template>
  <div>
    <ClientOnly>
      <div class="step-desktop d-none d-md-flex align-items-center justify-content-center">
        <template v-for="(step, i) in steps">
          <div
            v-if="i !== 0"
            class="step-line bg-primary ms-24"
          />
          <div
            class="step-item"
            :class="{
              'ms-24': i !== 0,
              'valid': step.isValid,
              'active': i === activeStep,
            }"
          >
            <button
              class="step-item__number d-block btn p-0 mx-auto mb-10 rounded-circle shadow-1"
              type="button"
              @click="changeStep(i)"
            >
              <span>{{ i + 1 }}</span>
              <svg class="w-100 h-100 p-10">
                <use xlink:href="/public/icons/main.svg#check" />
              </svg>
            </button>
            <button
              class="step-item__title btn py-10 px-24 rounded-40 shadow-1"
              type="button"
              @click="changeStep(i)"
            >
              {{ step.title }}
            </button>
          </div>
        </template>
      </div>
      <div
        class="d-block d-md-none rounded-20 bg-white p-20"
        style="padding-bottom: 6.5px !important"
      >
        <div class="d-flex">
          <ve-progress
            class="flex-shrink-0"
            :progress="((activeStep+1) * 100) / steps.length"
            color="rgba(var(--bs-primary-rgb))"
            empty-color="rgba(var(--bs-apply-rgb))"
            size="64"
            thickness="5"
            animation="default 500 0"
            empty-thickness="5"
            :hide-legend="true"
          >
            <template #legend-caption>
              <span
                class="text-arsenic-500 d-block"
                style="font-size: 12px"
              >
                Шаг
              </span>
              <span style="font-size: 12px">{{ activeStep + 1 }} / {{ steps.length }}</span>
            </template>
          </ve-progress>
          <div class="ms-15">
            <div class="text-primary fw-bold">
              {{ steps[activeStep]?.title }}
            </div>
            <div style="font-size: 12px;">
              {{ steps[activeStep]?.description }}
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-between">
          <AppButton
            class="text-dark"
            :class="{ invisible: activeStep === 0 }"
            :variant="BtnVariant.Link"
            @click="changeStep(activeStep - 1)"
          >
            <template #prepend-icon>
              <svg class="w-100 h-100 flip-horizontal">
                <use xlink:href="/public/icons/main.svg#east" />
              </svg>
            </template>
            Назад
          </AppButton>
          <AppButton
            :class="{ invisible: activeStep === steps.length-1 }"
            :variant="BtnVariant.Link"
            @click="nextStep"
          >
            Далее
            <template #append-icon>
              <svg class="w-100 h-100">
                <use xlink:href="/public/icons/main.svg#east" />
              </svg>
            </template>
          </AppButton>
        </div>
      </div>
    </ClientOnly>
  </div>
</template>

<script setup lang="ts">
import { BtnVariant } from "~/composables/enums/BtnVariant";
import type { PropType } from "vue";

type Step = {
  title: string,
  description: string,
  isValid: boolean,
};

//-----PROPS-----\\
defineProps({
  steps: { type: Array as PropType<Step[]>, default: () => [] },
  activeStep: { type: Number, default: 0 },
});
const emits = defineEmits(['changeStep', 'nextStep']);

//-----VARIABLES-----\\

//-----STATE-----\\

//-----COMPUTED-----\\

//-----ASYNC-----\\

//-----METHODS-----\\
function changeStep(step: number) {
  emits('changeStep', step);
}
function nextStep() {
  emits('nextStep');
}
</script>

<style scoped lang="scss">
.step-item{
  .step-item__number {
    color: var(--bs-primary);
    width: 60px;
    height: 60px;
    font-size: 40px;
    line-height: 60px;

    & > svg { display: none; }
  }
  &.valid .step-item__number {
    & > span { display: none; }
    & > svg { display: block; }
  }
  .step-item__title {
    color: var(--bs-dark);
    font-size: 18px;
    line-height: 32px;
  }

  .step-item__title, .step-item__number {
    background-color: white;
  }

  &.valid .step-item__title, &.valid .step-item__number {
    background-color: var(--bs-apply);
    color: var(--bs-primary);
  }

  &.active .step-item__title, &.active .step-item__number {
    background-color: var(--bs-primary);
    color: white;
  }
}
.step-line {
  height: 1px;
  width: 40px;
}
</style>